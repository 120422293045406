import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Stack,
  UnorderedList,
} from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { Head } from "../../../components/Head";
import Layout from "../../../components/Layout";
import Section from "../../../components/Section";
import { SharedHero } from "../../../components/shared/SharedHero";
import { waitForGlobal } from "../../../utils";

const H4: React.FC = ({ children }) => (
  <Heading as={"h4"} fontSize={["24px", "32px"]} fontWeight={"400"}>
    {children}
  </Heading>
);

const H5: React.FC = ({ children }) => (
  <Heading as={"h5"} fontSize={["18px", "24px"]} fontWeight={"400"}>
    {children}
  </Heading>
);

const H6: React.FC = ({ children }) => (
  <Heading as={"h6"} fontSize={["14px", "18px"]} fontWeight={"600"}>
    {children}
  </Heading>
);

const Strong: React.FC = ({ children }) => (
  <Text fontWeight="bold">{children}</Text>
);

const OTembedContainer = styled.div`
  font-size: 1rem !important;
  color: black !important;

  > *,
  > * > *,
  > * > * > *:not(table) {
    font-size: inherit !important;
    color: inherit !important;
    text-align: justify !important;
    line-height: 24px !important;
    font-family: "TIM Sans", system-ui, sans-serif !important;
    font-weight: 400 !important;
  }

  //table th,
  //table td {
  //  color: inherit !important;
  //  text-align: left !important;
  //}

  h3,
  h4 {
    font-weight: 400 !important;
  }

  h3 {
    font-size: 18px !important;
  }

  h4 {
    font-size: 16px !important;
  }

  @media (min-width: 480px) {
    h3 {
      font-size: 24px !important;
    }

    h4 {
      font-size: 20px !important;
    }
  }
`;

/**
 * Load OneTrust Cookie Policy HTML
 * sdk: OneTrust.initializeCookiePolicyHtml()
 */
const loadCookiePolicyHtml = () => {
  waitForGlobal("OneTrust", () => {
    const cookiePolicyHtmlContainer = document.getElementById(
      "ot-sdk-cookie-policy"
    );
    setTimeout(function () {
      if (!cookiePolicyHtmlContainer?.hasChildNodes()) {
        window.OneTrust.initializeCookiePolicyHtml();
      }
    }, 500);
  });
};

const InformativaCookies: React.FC = () => {
  useEffect(() => loadCookiePolicyHtml(), []);

  return (
    <Layout>
      <Head title="Informativa cookie" />

      <SharedHero />

      <Section>
        <Box>
          <Stack spacing={4} direction={"column"} textAlign={"justify"}>
            <H4>Informativa Cookie</H4>
            <Text>
              Gentile Utente,
              <br /> i cookie sono piccoli file di testo che i siti visitati
              dall’utente inviano al suo terminale dove vengono memorizzati per
              essere poi ritrasmessi agli stessi siti alla successiva visita del
              medesimo utente. Con riferimento alla navigazione via web, si
              informa che il sito utilizza i cookie per rendere i propri servizi
              semplici ed efficienti per gli Utenti che visitano e utilizzano le
              pagine del sito stesso.
            </Text>
            <Text>
              I cookie vengono utilizzati per rendere più immediato l’utilizzo
              del sito e/o abilitare determinate funzionalità. Quando i cookie
              vengono installati dal sito che l’utente sta visitando, questi
              vengono definiti “cookie di prima parte”. Oltre ai cookie inviati
              dal sito web che si sta visitando, in taluni casi nel corso della
              navigazione possono essere trasmessi cookie di siti o web server
              diversi. In questo caso si parla di cookie di terza parte. Questo
              perché, ogni sito, può presentare elementi diversi che risiedono
              su server diversi da quelli del sito visitato.
            </Text>
            <Text>
              Le finalità di utilizzo dei cookie sono varie, ad esempio:
            </Text>
            <UnorderedList pl={8}>
              <ListItem>
                agevolare la consultazione del sito e migliorare l’esperienza di
                navigazione;
              </ListItem>
              <ListItem>eseguire l’autenticazione informatica;</ListItem>
              <ListItem>monitorare sessioni;</ListItem>
              <ListItem>
                acquisire informazioni statistiche relative alla navigazione
                degli utenti;
              </ListItem>
              <ListItem>memorizzare informazioni specifiche;</ListItem>
              <ListItem>
                veicolare messaggi pubblicitari in linea con le preferenze di
                navigazione;
              </ListItem>
            </UnorderedList>
            <Text>
              I cookie si presentano, a volte, come tecnicamente necessari, si
              pensi al caso in cui consentono di mantenere l’identificazione
              dell’utente nell’ambito di procedure ad elevati standard di
              sicurezza (es. home banking).
            </Text>
            <Text>
              Dal punto di vista del trattamento dei dati personali, la
              rilevanza dei cookie è legata al fatto che alcuni di essi possono
              rimanere memorizzati nel terminale dell’utente anche per lunghi
              periodi e possono avere associato un codice identificativo unico.
              Questo codice consente di tenere traccia delle visite e della
              navigazione effettuate dall’utente all’interno del sito e di
              utilizzare l’informazione così acquisita per finalità statistiche
              o pubblicitarie.
            </Text>
            <Text>
              I cookie, invece, che esauriscono la loro funzione nell’ambito
              della singola visita al sito sono detti cookie di sessione e i
              cookie di navigazione utilizzati al solo fine di effettuare la
              trasmissione di comunicazione di dati navigazione e necessari al
              fornitore a erogare tale servizio. La normativa italiana prevede
              per i cookie, in via di principio, la regola del consenso, vale a
              dire che gli stessi possono essere utilizzati dal sito nei
              confronti dell’utente che vi naviga solamente con il suo consenso.
              Fanno eccezione alla regola del consenso i cosiddetti cookie
              tecnici. Essi possono essere utilizzati liberamente, anche senza
              il consenso dell’utente, ma è necessaria una specifica
              informativa.
            </Text>
            <Text>
              Ad esempio, sono cookie per l’utilizzo dei quali non è necessario
              raccogliere il consenso preventivo e informato del cliente:
            </Text>
            <OrderedList pl={4}>
              <ListItem>
                i cookie impiantati nel terminale dell’utente/contraente
                direttamente dal titolare del singolo sito web, se non sono
                utilizzati per scopi ulteriori;
              </ListItem>
              <ListItem>
                i cookie utilizzati per analizzare statisticamente gli
                accessi/le visite al sito (c.d. cookie analytics), se hanno
                solamente scopi statistici e raccolgono informazioni in maniera
                aggregata/anonima; in questo caso però occorre che il sito
                fornisca una informativa chiara e adeguata e offra agli utenti
                modalità semplici per opporsi (c.d. opt-out) al loro impianto.
              </ListItem>
            </OrderedList>
            <Text>
              Pertanto, fatta eccezione per il caso dei cookie tecnici, la
              regola generale è quella del consenso preventivo ed informato
              dell’utente (c.d. opt-in). L’utilizzo dei cookie tecnici è
              liberamente consentito, salvo preventiva e idonea informativa.
            </Text>
            <Text>
              Per quanto concerne il consenso ai cookie diversi da quelli
              tecnici (es. i cookie di prestazione e i cookie funzionali),
              l’utente potrà in qualsiasi momento modificarlo, accedendo alla
              sezione “Privacy e cookie” di questo sito internet.
            </Text>

            {/* OneTrust */}
            <H5>I COOKIE UTILIZZATI DA QUESTO SITO</H5>
            <OTembedContainer id="ot-sdk-cookie-policy" />
            {/* /OneTrust */}

            <Divider />
            <H5>LE MODALITÀ DI OPPOSIZIONE ALL’UTILIZZO DEI COOKIE</H5>
            <Text>
              Benché bloccare i cookie possa comportare l’impossibilità di
              godere delle funzionalità del sito visitato, l’utente può opporsi
              all’utilizzo dei cookie da parte del sito sul suo terminale. Ciò
              può avvenire, ad esempio, operando secondo una delle seguenti
              modalità, disponibili mediante un corretto uso dei browser
              internet più diffusi (Internet Explorer, ecc.):
            </Text>
            <OrderedList pl={4}>
              <ListItem>
                optare per la navigazione anonima/riservata, oppure
              </ListItem>
              <ListItem>
                computer specifici applicativi (c.d. plug-in), ordinariamente
                gratuiti e disponibili sui siti dei produttori del browser, che
                consentono la gestione e disattivazione dei cookie utilizzati
                dai siti internet (ad es., per impedire a Google Analytics di
                raccogliere dati sulla tua navigazione, puoi scaricare il
                Componente aggiuntivo del browser all’indirizzo:{" "}
                <Link
                  isExternal
                  color="accent.01"
                  href="https://tools.google.com/dlpage/gaoptout"
                >
                  https://tools.google.com/dlpage/gaoptout
                  <ExternalLinkIcon
                    ml="4px"
                    position={"relative"}
                    top={"-3px"}
                  />
                </Link>
                ), oppure
              </ListItem>
              <ListItem>
                impostare il proprio browser nel senso di non consentire la
                memorizzazione dei cookie
              </ListItem>
            </OrderedList>
            <Text>
              Nella normalità dei casi, con le opzioni 2. e 3. è possibile
              selezionare fra tipologie di cookie ammesse (ad es. non
              consentendo l’utilizzo di cookie di terze parti).
            </Text>
            <Text>
              Tutte e tre le modalità sono disattivabili in qualunque momento e
              liberamente da parte dell’utente.
            </Text>
            <Text>
              Qui sotto trovi alcuni dettagli operativi su come modificare le
              impostazioni riguardanti i cookie presenti su alcuni fra i browser
              di più comune utilizzo.
            </Text>
            <H6>Microsoft Internet Explorer e/o Microsoft Edge: </H6>
            <Text>
              clicca sull’icona “Strumenti” (attualmente, a forma di ruota di
              ingranaggio) posta nell’angolo in alto a destra della homepage e,
              nel menù a tendina che si apre, seleziona la scelta “Opzioni
              internet”. Nella successiva finestra che compare, seleziona
              l’etichetta “Privacy” e, in “Impostazioni”, seleziona “Avanzate”,
              quindi scegli se vuoi accettare, bloccare o ricevere una richiesta
              per i cookie proprietari o di terze parti.
            </Text>
            <Link
              isExternal
              color="accent.01"
              href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d"
            >
              https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d
              <ExternalLinkIcon ml="4px" position={"relative"} top={"-3px"} />
            </Link>

            <H6>Google Chrome:</H6>
            <Text>
              clicca sul menù Chrome (attualmente, a forma di tre righe
              orizzontali sovrapposte) posta nell’angolo in alto a destra della
              homepage, scegli l’opzione “Impostazioni” e, in fondo alla pagina
              che si apre, clicca su “Mostra impostazioni avanzate”. Nella
              sezione “Privacy” che si aggiunge alla pagina, clicca quindi su
              “Impostazione contenuti” e, nel paragrafo “Cookie”, puoi scegliere
              la relativa impostazione desiderata.
            </Text>
            <Link
              isExternal
              color="accent.01"
              href="https://support.google.com/chrome/answer/95647?hl=it"
            >
              https://support.google.com/chrome/answer/95647?hl=it
              <ExternalLinkIcon ml="4px" position={"relative"} top={"-3px"} />
            </Link>

            <H6>Mozilla Firefox:</H6>
            <Text>
              clicca sul menù (attualmente, a forma di tre righe orizzontali
              sovrapposte) in alto nella homepage, scegli l’opzione
              “Impostazioni” e successivamente il pannello “Privacy”, al cui
              interno potrai scegliere le impostazioni relative ai cookie
              desiderati.
            </Text>
            <Link
              isExternal
              color="accent.01"
              href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
            >
              https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie
              <ExternalLinkIcon ml="4px" position={"relative"} top={"-3px"} />
            </Link>

            <H6>Safari:</H6>
            <Text>
              all’interno del menù a tendina posto nell’angolo in alto a destra
              della homepage, clicca su “Preferenze” e dal pannello Privacy puoi
              scegliere di bloccare i cookie.
            </Text>
            <Link
              isExternal
              color="accent.01"
              href="https://support.apple.com/it-it/guide/safari/sfri11471/mac"
            >
              https://support.apple.com/it-it/guide/safari/sfri11471/mac
              <ExternalLinkIcon ml="4px" position={"relative"} top={"-3px"} />
            </Link>

            <H6>
              Se invece stai navigando dal tuo smartphone seleziona il sistema
              operativo che stai utilizzando:
            </H6>
            <UnorderedList my={3} pl={4}>
              <ListItem>
                <H6>Sistema operativo Android</H6>
                <Text>
                  Avvia il browser web: una volta che il browser è avviato,
                  premi il bottone menù del tuo telefono, seleziona l’opzione
                  “Impostazioni”, seleziona la voce “Impostazioni contenuto”,
                  scorri la lista cercando la voce “Accetta cookie” e
                  deseleziona il pulsante di spunta.
                </Text>
                <UnorderedList my={3}>
                  <ListItem>
                    <Strong>Chrome:</Strong>
                    <Text>
                      avvia il browser Chrome: premi il bottone menù del tuo
                      telefono, seleziona l’opzione “Impostazioni”, seleziona la
                      voce “Impostazioni sito”, sposta l'interruttore della voce
                      “Accetta Cookie” sulla posizione 0. Per i cookie di terze
                      parti, deseleziona il pulsante di spunta su “Consenti
                      cookie di terze parti”;
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Strong>Firefox:</Strong>
                    <Text>
                      apri Firefox per Android: seleziona il pulsante menù,
                      seleziona impostazioni, seleziona Privacy, seleziona
                      Cookie e scegliere l'impostazione “Disattivati” che
                      impedisce a tutti i tipi di cookie di essere conservati
                      sul dispositivo;
                    </Text>
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                <H6>Sistema Operativo IOS</H6>
                <UnorderedList my={3}>
                  <ListItem>
                    <Strong>Safari:</Strong>
                    <Text>
                      entra nel pannello di controllo delle “Impostazioni”,
                      seleziona la voce “Safari” nella sezione “Privacy e
                      Sicurezza” e selezionare “Blocca i Cookie”, nel menù
                      successivo scegliere “Sempre”;
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Strong>Chrome:</Strong>
                    <Text>
                      apri il menù Chrome, seleziona "Impostazioni Nella sezione
                      “Avanzate”, seleziona “Impostazioni contenuti”, sposta
                      l’interruttore della voce “Accetta Cookie” sulla posizione
                      0.
                    </Text>
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
            <Strong>
              Ti ricordiamo che se disattivi i cookie, potresti disabilitare
              alcune funzionalità del sito. Anche con tutti i cookie
              disabilitati, il tuo browser continuerà a memorizzare una piccola
              quantità di informazioni, necessarie per le funzionalità di base
              del sito.
            </Strong>

            <H5>MODIFICA DELLE PREFERENZE DI CONSENSO</H5>
            <Text>
              Puoi modificare in qualsiasi momento le preferenze di consenso già
              espresse, entrando nella sezione “Privacy e Cookie” posta nel
              footer del sito e cliccando l’apposito tasto posto sotto la voce
              “Vuoi modificare le tue preferenze o revocare i consensi sui
              cookie?”.
            </Text>
          </Stack>
        </Box>
      </Section>
    </Layout>
  );
};

export default InformativaCookies;
